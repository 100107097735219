import React from 'react';

function Footer(props){

    return(
        <footer className="footer">
        <div className="content has-text-centered">
          <p>
             <a onClick={props.onClick}> &copy; Ronald Royales 2019 </a>
          </p>
        </div>
      </footer>
    )
}

export default Footer;




