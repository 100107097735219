import React from 'react'
import moment from 'moment'
import { Link as LinkRouter } from 'react-router-dom'
import axios from 'axios';
import Modal4 from './Modal4';

export default class Scheduler extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      available: [],
      contact: false,
      dateCal: 0,
      myHtml: [],
      online: false,
    }
  }
  componentDidMount(){ 
    const url = "https://rocous.com/route/available_date/"
    //const url = "http://127.0.0.1:8000/api/route/available_date/"

    axios.get(url)
      .then(response => {
        const available = response.data;
        this.setState({available})
      })
      .catch(function (error) {
        console.log(error);
      }); 
}

componentDidUpdate(prevProps, prevState){
 if(prevState.contact !== this.state.contact ){
   const url = "https://rocous.com/route/available_date/"
   //const url = "http://127.0.0.1:8000/api/route/available_date/"
  axios.get(url)
    .then(response => {
      const available = response.data;
      this.setState({available})
    })
    .catch(function (error) {
      console.log(error);
    }); }}

contact(){
        this.setState((prevState, prevProps) =>  ({
          contact: !prevState.contact
        }) )}

alerted(i){
  this.setState((state)=>{
    return{
    contact: !state.contact,
    dateCal: i
  }})
}

htmlFormat(date, time, taken, id, phone){
  var mapDate = (date + 'T' + time).toString();
  var linkIndex = "/calendar/" + id;
  if(!taken)
    return(
        <div key={id} className="column">
        <div className="card">
        <div className="card-content" >
         <p>
              Date: {moment(mapDate).format('MMMM DD, YYYY')} <br />
              Time: {moment(mapDate).format('h:mm a')}
              </p>
          <label className="checkbox">
            <input type="checkbox" readOnly checked = {phone? 'checked' : ""}/>
              &nbsp; Phone only 
          </label>
        </div>
      
        <footer className="card-footer ">
          <p className="card-footer-item">
          <button onClick={()=>this.alerted(id)}
          
          className="button is-primary">
          <span className="icon is-medium">
            <i className="fa fa-plus"></i>
          </span>
          <span>Create</span>
        </button>
          </p>
        </footer>
       
      </div>
        </div>
    )
    else{
      return(
        <div  key={id} className="column">
        <div className="card">
        <div className="card-content" >
         <p>
         Date: {moment(mapDate).format('MMMM DD, YYYY')} <br />
              Time: {moment(mapDate).format('h:mm a')}
              </p>
           <label className="checkbox">
            <input type="checkbox" readOnly checked/>
              &nbsp; unavailable
          </label> 
        </div>
         <footer className="card-footer ">
          <p className="card-footer-item">
          <button className="button is-primary" disabled>
          <span>Taken</span>
        </button>
          </p>
        </footer> 
      </div>
        </div>
      )
    }
}

render(){
    var insideRow = [];
    var insideRow2 = [];
    var getHtml = [];

    this.state.available.map((val, i)=>{
    if(i<4){
        insideRow.push(
         this.htmlFormat(val.date, val.time, val.schedtaken, val.id, val.phone)
        )
    }
    else if(i>=4 && i<8){
        insideRow2.push(
            this.htmlFormat(val.date, val.time, val.schedtaken, val.id, val.phone)
        )
    }
    }
    )
     getHtml.push(           
        <div className="container is-fluid">
        <div className="columns">
         {insideRow}
        </div> </div>
          )
    if(insideRow2 === undefined || insideRow2.length == 0){
    }
    else{
      getHtml.push(
        <div className="container is-fluid">
        <div className="columns">
      {insideRow2} 
      </div> </div>)
    } 
      return(<div>
        <Modal4 onClick={()=>this.contact()} 
                contact={this.state.contact}
                dateCal = {this.state.dateCal} />
                <h1 class="title is-1 has-text-black-bis 
                           has-text-centered roboto"> My Calendar </h1>
        {getHtml}
        </div>
      )
    }
  }