import React from 'react';
import ronfront from './images/ronbg5.jpg';

function Backend(props){
    return(
      <div>
        <section className="section">
             
                <div className="container">
  <div className="content">
  <h1 className="align-centered roboto">BACKEND DEVELOPMENT</h1>
  <p className="notification openSans">I am experienced in a wide variety of backend programming languages and platforms, including PHP, Python/Django, Apache and WordPress.
I have also worked with many different databases including SQLite, MySQL, and PostgreSQL.

Depending on the goals and expectations, I will help you select the appropriate technology for your website.  </p>
  </div>
</div>
            </section>
            <figure>
  <img alt="Ronald Royales" src={ronfront}/>
</figure>
            </div>
    )
}

export default Backend;