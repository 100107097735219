import React from 'react';

function Home(props){
    return(
        <section className="hero is-primary is-fullheight header-image">
                {/* <!-- Hero content: will be in the middle --> */}
                <div className="hero-body">
                    <div className="container has-text-centered">
                        <h1 className="title roboto">
                            STARTUP PROJECT
                        </h1>
                        <h2 className="subtitle">
                            <a 
                              className="button is-success enter openSans " 
                              target="_blank" 
                            //   href="https://www.helplookup.com"
                              onClick={props.onClick}>
                             check availability
                            </a>

                        
                        </h2>
                    </div>
                </div>
            </section>
    )
}

export default Home;