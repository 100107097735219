import React , {useState} from 'react';
import { withFormik, Form, Field } from 'formik'
import * as Yup from 'yup';
import axios from 'axios';
import { Link as LinkRouter } from 'react-router-dom'

const FormApp = ({
  values,
  errors,
  touched,
  isSubmitting
  }) => { 
      var oErrorCount = Object.keys(errors).length;
    return (
    
    <div>
      <h1>{values.dateCal}</h1>
          <h3 className="help is-danger roboto">
           { touched.question && oErrorCount >= 1 ? 'Please fix the error(s)' : null }
            </h3>
        <Form>
              <div className="paddedtb">
              <span className="help is-black has-text-left">
                  Name: 
                  </span>
                 <Field className="input" type="text" name="name" placeholder="Name"/>
                  <p className="help is-danger has-text-right">
                  { touched.name && errors.name && <span>{errors.name}</span>}
                  </p>
              </div>

              <div className="paddedtb">
              <span className="help is-black has-text-left">
                  Email: 
                  </span>
                  <Field name="email" className="input" type="email" placeholder="Email"/>
                  <p className="help is-danger has-text-right">
                  { touched.email && errors.email && <span>{errors.email}</span>}
                  </p>
              </div>
              <div className="paddedtb">
              <span className="help is-black has-text-left">
                  Phone#: 
                  </span>
                  <Field name="phone" className="input" type="text" placeholder="Phone#"/>
                  <p className="help is-danger has-text-right">
                  { touched.phone && errors.phone && <span>{errors.phone}</span>}
                  </p>
              </div>
              <div className="paddedtb">
              <span className="help is-black has-text-left">
                  Message: 
                  </span>
                  <Field 
                      className="textarea" 
                      name="question" 
                      placeholder="Message"
                      component="textarea">
                  </Field>
                  <p className="help is-danger has-text-right">
                  
                  { touched.question && errors.question && <span>{errors.question}</span>}
                  </p>
              </div>
              <br />
              <div className="field is-grouped is-grouped-centered">
                  <div className="control">
                      <button className="button is-light" type="reset" >Reset</button>
                  </div>
                  <div className="control">
                      <button 
                          name="btnSubmit" 
                          className="button is-primary" 
                          type="submit" 
                          disabled={isSubmitting}>
                            Submit
                      </button>
                  </div>
                </div>
        </Form>
    </div>
)}

const FormikApp = withFormik({
  mapPropsToValues({ name, email, phone, question}) {
    return {
      name: name || '',
      email: email || '',
      question: question || '',
      phone: phone || '',

    }
  },
  validationSchema: Yup.object().shape({
    name: Yup.string().required('Name is required'),
    email: Yup.string().email('Email is not valid').required('Email is required'),
    phone: Yup.string().required('phone number is required'),
    question: Yup.string().max(600, 'Message should be less than 600 characters long')
                          .required('Message is required')
  }),
  handleSubmit(values, { props, resetForm, setErrors, setSubmitting }) {
      const num = props.dateCal;
      const myDate = "https://www.rocous.com/route/available_date/" + num + '/';
      axios.post('https://www.rocous.com/route/appointment/', {   
      name: values.name,
      email: values.email,
      phone: values.phone,
      message: values.question,
      date: myDate
    })
    .then(function (response) {
      props.closeForm(); 
      setTimeout(()=>{
          props.onClick();
          props.closeForm();
      }, 2000)
    })
    .catch(function (error) {
      resetForm();
      alert("network error please check your internet.");
    }); 
  }
})(FormApp)


export default FormikApp;
