import React, { Component } from 'react';
import FormikApp from './Forms/basic'; 

class Modal extends Component {
  constructor(props){
    super(props);
    this.state = {
      close: true,
    }
    this.iconClick = this.iconClick.bind(this);
  }
  iconClick(){
    this.props.onClick();
  }
  closeForm(){
    this.setState({
      close: !this.state.close
    })
  }

  render(){
    let card_head;
    let card_body;
    let card_foot;
    if(this.state.close){
      card_head = <React.Fragment><p className="modal-card-title roboto">Contact Me</p>
      <button onClick={this.iconClick} className="delete" aria-label="close"></button>
      </React.Fragment>
    }
    else
    {
      card_head = <React.Fragment>
                    <p  class="modal-card-title roboto">Thank You!</p>
                   <br />
                   </React.Fragment>
    }

    if(this.state.close){
      card_body = <React.Fragment>
                      <FormikApp 
                      onClick={()=>this.props.onClick()}
                      closeForm={()=>this.closeForm()}
                      />
                </React.Fragment>
    }
    else
    {
      card_body = <React.Fragment>
                    <p  class="modal-card-title roboto">Your message has been sent</p>
                    </React.Fragment>
    }           

    if(!this.state.close){
      card_foot =   <footer class="modal-card-foot has-text-centered">

                    </footer>    
    } 

        return(
          <div className={this.props.contact ?   "modal is-active": "modal"}>
          <div className="modal-background"></div>
      <div className={this.props.contact ? "modal-card is-active": "modal-card"}>
      <header className="modal-card-head">
            {card_head}
      </header>
      <section className="modal-card-body">
      <div className="card-content">
      <div className="content has-text-centered">
        {card_body}
      </div>
      </div>
      </section>
        {card_foot}
      </div>
          </div>
      )
  }
}

export default Modal;
