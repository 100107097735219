import React, { Component } from 'react';
import classNames from 'classnames';
import './App.css';
import Home from './Home';
import Home2 from './Home2';
import Footer from './Footer';
import Frontend from './Frontend';
import Backend from './Backend';
import ron from './images/ron1.png';
import Modal from './Modal3';
import {Waypoint} from 'react-waypoint';
 import { Link,  Events, scrollSpy } from 'react-scroll'
 import { Link as LinkRouter } from 'react-router-dom'


class App extends Component {
  constructor(props){
    super(props);
    this.state = {
      logged: true,
      contact: false,
      hamburger: false,
      navSeen: false,
    }
    this.clickLog = this.clickLog.bind(this);
    this._handleWaypointEnter = this._handleWaypointEnter.bind(this);
    this._handleWaypointLeave = this._handleWaypointLeave.bind(this);
  }

  _handleWaypointEnter(){
      this.setState({
        navSeen: !this.state.navSeen
    })
  }

  _handleWaypointLeave(){
      this.setState({
        navSeen: !this.state.navSeen
    })
  }

  clickLog(){
    this.setState({
      logged: !this.state.logged
    })
  }

  contact(){
    this.setState({
      contact: !this.state.contact
    })
  }

  hamburger(){
    this.setState({
      hamburger: !this.state.hamburger
    })
  }

componentDidMount() {
      Events.scrollEvent.register('begin', function(to, element) {
      });
      Events.scrollEvent.register('end', function(to, element) {
      });
      scrollSpy.update();
}

componentWillUnmount() {
    Events.scrollEvent.remove('begin');
    Events.scrollEvent.remove('end');
}

  render() {
    const seenIf = this.state.navSeen ? "hide" : "is-fixed-top";
    const seen = classNames("is-hidden-touch navbar is-link", seenIf);
    const burgerIf = this.state.hamburger ? "is-active" : "null";
    const hamburgerClass = classNames("navbar-burger burger", burgerIf);

    return (
      <div className="App">
      <nav name="test10" 
           className="navbar is-info " 
           role="navigation" 
           aria-label="main navigation">
              <div className="navbar-brand">
                  <a className="navbar-item" onClick={()=>this.contact()}>
                    <img src={require('./images/ron1.png')} width="112" height="28" alt="ronald royales"/>
                  </a>
                <a 
                  role="button" 
                  onClick={()=>this.hamburger()}
                  className={hamburgerClass}
                  aria-label="menu" 
                  aria-expanded="false" 
                  data-target="navbarBasicExample">
                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>
                </a>
            </div>

            <div id="navbarBasicExample" className="navbar-menu">
                    <div className="navbar-start">
                    <Link className="navbar-item" 
                          to="test10" 
                          spy={true} 
                          smooth="true" 
                          offset={0} 
                          duration={500} 
                    >
                        Home
                    </Link>
                    <Link className="navbar-item" 
                          activeClass="active" 
                          to="frontend" 
                          spy={true} 
                          smooth={true} 
                          offset={-50} 
                          duration={500} 
                          onSetActive={this.handleSetActive}
                    >
                          Frontend
                    </Link>
                    <Link className="navbar-item" 
                          activeClass="active" 
                          to="backend" 
                          spy={true} 
                          smooth={true} 
                          offset={-50} 
                          duration={500} 
                          onSetActive={this.handleSetActive}
                    >
                          Backend
                    </Link>

                    <LinkRouter className="navbar-item" 
                                activeClass="active" 
                                to="/calendar">
                          My Calendar
                    </LinkRouter>

                </div>
                <div className="navbar-end">
                <div className="navbar-item">
                    <div className="buttons">
                    <button 
                    className="button is-primary"
                    onClick={()=>this.contact()}
                    >
                    {this.state.contact}
                    {this.state.contact ? "Close" : "Contact Me"}
                    </button>
                    </div>
                </div>
                </div>
  </div>
</nav>
 <div className={this.state.hamburger ? "navbar-dropdown" : "hide"} > 
      <hr className="hrPad2"/>
      <Link className="navbar-item" 
            activeClass="active" 
            to="frontend" 
            spy={true} 
            smooth={true} 
            offset={0} 
            duration={500} 
            onSetActive={this.handleSetActive}>
          Frontend
        </Link>
        <hr className="hrPad2"/>
        <Link className="navbar-item" 
                          activeClass="active" 
                          to="backend" 
                          spy={true} 
                          smooth={true} 
                          offset={-50} 
                          duration={500} 
                          onSetActive={this.handleSetActive}
                    >
                          Backend
        </Link>
        <hr className="hrPad2"/>
        <LinkRouter className="navbar-item" 
                                activeClass="active" 
                                to="/calendar">
                          My Calendar
                    </LinkRouter>
      <hr className="hrPad2"/>
      <a className="navbar-item " onClick={()=>this.contact()}>
                    {this.state.contact}
                    {this.state.contact ? "Close" : "Contact Me"}
      </a>
      <hr className="hrPad2"/>
    </div>
    <Waypoint
        onEnter={this._handleWaypointEnter}
        onLeave={this._handleWaypointLeave}
    />
<nav className={seen} 
     role="navigation" aria-label="main navigation">
            <div className="navbar-brand">
            <a className="navbar-item" onClick={()=>this.contact()}>
                    <img src={ron} width="112" height="28" alt="ronald royales"/>
                  </a>
</div>
            <div id="navbarBasicExample" className="navbar-menu">
                    <div className="navbar-start">
                    <Link className="navbar-item" 
                          to="test10" 
                          spy={true} 
                          smooth="true" 
                          offset={0} 
                          duration={500} 
                    >
                        Home
                    </Link>
                    <Link className="navbar-item" 
                          activeClass="active" 
                          to="frontend" 
                          spy={true} 
                          smooth={true} 
                          offset={-50} 
                          duration={500} 
                          onSetActive={this.handleSetActive}
                    >
                          Frontend
                    </Link>
                    <Link className="navbar-item" 
                          activeClass="active" 
                          to="backend" 
                          spy={true} 
                          smooth={true} 
                          offset={-50} 
                          duration={500} 
                          onSetActive={this.handleSetActive}
                    >
                          Backend
                    </Link>
                    <LinkRouter className="navbar-item" 
                                activeClass="active" 
                                to="/calendar">
                          My Calendar
                    </LinkRouter>
                </div>
                <div className="navbar-end">
                <div className="navbar-item">
                    <div className="buttons">
                    <button 
                    className="button is-success"
                    onClick={()=>this.contact()}
                    >
                    {this.state.contact}
                    {this.state.contact ? "Close" : "Contact Me"}
                    </button>
                    </div>
                </div>
                </div>
  </div>
</nav>
      <Modal onClick={()=>this.contact()} contact={this.state.contact} />
      <Home onClick={()=>this.contact()} contact={this.state.contact}/>
      <div name="frontend" className="element">
       <Frontend />
       </div> <br/> <br/>
       <Home2 onClick={()=>this.contact()} contact={this.state.contact}/>
        <br/>
        <div name="backend" className="element">
        <Backend />
       </div>
       <Footer onClick={()=>this.contact()}/>
      </div>
    );
  }
}

export default App;

