import React from 'react';
import ronfront from './images/ronbg4.jpg';

function Frontend(props){
    return(
        <div>
        <section className="section">
             
                <div className="container">
  <div className="content">
  <h1 className="align-centered roboto">FRONTEND DEVELOPMENT</h1>
  <div className="notification ">
  <p className="openSans">I am experienced in a wide variety of frontend programming languages and platforms, including Angular, React, IOS, Bootstrap, Bulma, Ajax, JavaScript, JQuery, XHTML, XML, Flash, HTML5 and CSS3. </p>
  </div>
  </div>
</div>


            </section>

            <figure>
  <img alt="Ronald Royales" src={ronfront}/>
</figure>
            </div>
    )
}

export default Frontend;