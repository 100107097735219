import React from 'react'
import './App.css';
import Footer from './Footer';
import {Link} from 'react-router-dom'
import classNames from 'classnames';
import ron from './images/ron1.png';
import Modal from './Modal3';
//import Home from './Home';
//import Home2 from './Home2';
//import Card from './Card';
//import Card2 from './Card2';
//import TodoApp from './TodoApp';
//import Scheduler from './Scheduler';
import Scheduler from './SchedulerClass';
//import axios from 'axios';


class Schedule extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      /* available: [], */
      logged: true,
      contact: false,
      hamburger: false,
      navSeen: false,
      dates: [1,2,3,4,5,6],
      myHtml: [],
    }

  }

/*   componentDidMount(){ 

      const url = "http://127.0.0.1:8000/api/route/available_date/"
      axios.get(url)
        .then(response => {
          const available = response.data;
          this.setState({available})
        })
        .catch(function (error) {
          console.log(error);
        }); 

  

  } */

  contact(){
    this.setState({
      contact: !this.state.contact
    })
  }

  hamburger(){
    this.setState({
      hamburger: !this.state.hamburger
    })
  }

  nav(){
      
    const seenIf = this.state.navSeen ? "hide" : "is-fixed-top";
    const seen = classNames("is-hidden-touch navbar is-link", seenIf);
    const burgerIf = this.state.hamburger ? "is-active" : "null";
    const hamburgerClass = classNames("navbar-burger burger", burgerIf);
   // const {params} = this.props.match
    return( 
      <div>
    <nav name="test10" 
    className="navbar is-info " 
    role="navigation" 
    aria-label="main navigation">
       <div className="navbar-brand">
           <a className="navbar-item" onClick={()=>this.contact()}>
             <img src={ron} width="112" height="28" alt="ronald royales"/>
           </a>
         <a 
           role="button" 
           onClick={()=>this.hamburger()}
           className={hamburgerClass}
           aria-label="menu" 
           aria-expanded="false" 
           data-target="navbarBasicExample">
         <span aria-hidden="true"></span>
         <span aria-hidden="true"></span>
         <span aria-hidden="true"></span>
         </a>
     </div>
  
     <div id="navbarBasicExample" className="navbar-menu">
             <div className="navbar-start">
  
             <Link className="navbar-item" 
                         to="/">
                   Home
             </Link>
         </div>
         <div className="navbar-end">
         <div className="navbar-item">
             <div className="buttons">
             <button 
             className="button is-primary"
             onClick={()=>this.contact()}
             >
             {this.state.contact}
             {this.state.contact ? "Close" : "Contact Me"}
             </button>
             </div>
         </div>
         </div>
  </div>
  </nav>
  <div className={this.state.hamburger ? "navbar-dropdown" : "hide"} > 
  <hr className="hrPad2"/>
  
  
  <Link className="navbar-item" 
                         to="/">
                   Home
             </Link>
  <hr className="hrPad2"/>
  <a className="navbar-item " onClick={()=>this.contact()}>
             {this.state.contact}
             {this.state.contact ? "Close" : "Contact Me"}
  </a>
  <hr className="hrPad2"/>
  </div>
  <nav className={seen} 
  role="navigation" aria-label="main navigation">
     <div className="navbar-brand">
     <a className="navbar-item" onClick={()=>this.contact()}>
             <img src={ron} width="112" height="28" alt="ronald royales"/>
           </a>
  </div>
     <div id="navbarBasicExample" className="navbar-menu">
             <div className="navbar-start">
             <Link className="navbar-item" 
  
                         to="/">
                   Home
             </Link>
         </div>
         <div className="navbar-end">
         <div className="navbar-item">
             <div className="buttons">
             <button 
             className="button is-success"
             onClick={()=>this.contact()}
             >
             {this.state.contact}
             {this.state.contact ? "Close" : "Contact Me"}
             </button>
             </div>
         </div>
         </div>
  </div>
  </nav>
  </div>
    )}

  render() {
    


    return( 
    <div>
      {this.nav()}

      

<Modal onClick={()=>this.contact()} contact={this.state.contact} />





<section className="section">

{/*       <Scheduler myDate={this.state.available} /> */}
      <Scheduler />

</section>


 <Footer onClick={()=>this.contact()}/>
    </div>
    )
  }
}
export default Schedule