import React from 'react';
import { withFormik, Form, Field } from 'formik'
import * as Yup from 'yup';
import axios from 'axios';

const FormApp = ({
  values,
  errors,
  touched,
  isSubmitting
  }) => { 
/*     function isEmpty(obj) {
        for(var key in obj) {
            if(obj.hasOwnProperty(key))
                return false;
        }
        return true;
    } */
      var oErrorCount = Object.keys(errors).length;
    return (
    
    <div>
          <h3 className="help is-danger roboto">
           { touched.question && oErrorCount >= 1 ? 'Please fix the error(s)' : null }
            </h3>
        <Form>
              <div className="paddedtb">
              <span className="help is-black has-text-left">
                  Name: 
                  </span>
                  <Field className="input" type="text" name="name" placeholder="Name"/>
                  <p className="help is-danger has-text-right">
                  { touched.name && errors.name && <span>{errors.name}</span>}
                  </p>
              </div>

              <div className="paddedtb">
              <span className="help is-black has-text-left">
                  Email: 
                  </span>
                  <Field name="email" className="input" type="email" placeholder="Email"/>
                  <p className="help is-danger has-text-right">
                  { touched.email && errors.email && <span>{errors.email}</span>}
                  </p>
              </div>
              <div className="paddedtb">
              <span className="help is-black has-text-left">
                  Message: 
                  </span>
                  <Field 
                      className="textarea" 
                      name="question" 
                      placeholder="Message"
                      component="textarea">
                  </Field>
                  <p className="help is-danger has-text-right">
                  
                  { touched.question && errors.question && <span>{errors.question}</span>}
                  </p>
              </div>
              <br />
              <div className="field is-grouped is-grouped-centered">
                  <div className="control">
                      <button className="button is-light" type="reset" >Reset</button>
                  </div>
                  <div className="control">
                      <button 
                          name="btnSubmit" 
                          className="button is-primary" 
                          type="submit" 
                          disabled={isSubmitting}>
                            Submit
                      </button>
                    </div>
                </div>
        </Form>
    </div>
)}

const FormikApp = withFormik({
  mapPropsToValues({ closeForm, onClick, name, email, question}) {
    return {
      closeForm: closeForm,
      onClick: onClick,
      name: name || '',
      email: email || '',
      question: question || ''
    }
  },
  validationSchema: Yup.object().shape({
    name: Yup.string().required('Name is required'),
    email: Yup.string().email('Email is not valid').required('Email is required'),
    question: Yup.string().max(600, 'Message should be less than 600 characters long')
                          .required('Message is required')
  }),
  handleSubmit(values, { resetForm, setErrors, setSubmitting }) {
      axios.post('https://www.rocous.com/route/question/', {   
      name: values.name,
      email: values.email,
      question: values.question
    })
    .then(function (response) {
      values.closeForm();
      setTimeout(()=>{
          values.onClick();
          values.closeForm();
      }, 2000)
    })
    .catch(function (error) {
      resetForm();
      alert("network error or your email is wrong.");
    }); 
  }
})(FormApp)


export default FormikApp;
